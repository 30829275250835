<template>
  <div class="field has-addons" v-for="(email, key) in localEmails" :key="key">
    <p class="control">
      <span class="button" v-if="!isEditing">{{ email.type }} </span>
      <span class="select" v-if="isEditing">
        <select v-model="email.type" @change="updateType($event, key)">
          <option>Work</option>
          <option>Personal</option>
          <option>Other</option>
        </select>
      </span>
    </p>
    <div class="control is-expanded">
      <input
        class="input"
        type="email"
        :disabled="!isEditing"
        v-model="email.value"
        @input="updateValue($event, key)"
      />
    </div>
    <div class="control" v-if="isEditing">
      <div class="button" @click="deleteEmail(key)">
        <span class="icon has-text-danger">
          <i class="fas fa-trash"></i>
        </span>
      </div>
    </div>
  </div>

  <div class="field has-addons" v-if="isEditing">
    <div class="control">
      <span class="select is-small">
        <select v-model="newEmailType">
          <option>Work</option>
          <option>Personal</option>
          <option>Other</option>
        </select>
      </span>
    </div>
    <div class="control is-expanded">
      <input
        class="input is-small"
        type="text"
        :disabled="!isEditing"
        placeholder="Add a new Email"
        v-model="newEmail"
      />
    </div>
    <p class="control">
      <span class="button is-small" @click="saveNewEmail">
        <span class="icon">
          <i class="fas fa-save" :class="{ 'fa-spin': saveInProgress }"></i>
        </span>
      </span>
    </p>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { db, user } from '../components/firebase.js'
export default {
  props: {
    isEditing: Boolean,
    id: String,
    emails: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  setup (props) {
    const newEmailType = ref('Personal')
    const newEmail = ref('')
    const localEmails = ref({})
    const saveInProgress = ref(false)
    watchEffect(() => {
      if (props.emails) {
        localEmails.value = props.emails
      }
    })
    function deleteEmail (key) {
      console.log(key)
      delete localEmails.value[key]
      let count = 0
      const newEmails = {}
      Object.values(localEmails.value).forEach((l) => {
        newEmails[count] = l
        count++
      })
      db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        emails: newEmails
      })
    }
    async function updateType (event, key) {
      db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        [`emails.${key}.type`]: event.target.value,
        [`emails.${key}.metadata.updatedOn`]: new Date()
      })
    }
    async function updateValue (event, key) {
      db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        [`emails.${key}.value`]: event.target.value,
        [`emails.${key}.metadata.updatedOn`]: new Date()
      })
    }
    async function saveNewEmail () {
      saveInProgress.value = true
      // if (Object.keys(props.emails).length) {
      await db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        [`emails.${Object.keys(props.emails).length}`]: {
          metadata: {
            createdOn: new Date(),
            source: 'User Entered'
          },
          type: newEmailType.value,
          value: newEmail.value
        }
      })
      localEmails[Object.keys(props.emails).length] = {
        metadata: {
          createdOn: new Date(),
          source: 'User Entered'
        },
        type: newEmailType.value,
        value: newEmail.value
      }
      newEmail.value = ''
      saveInProgress.value = false
    }
    return {
      saveInProgress,
      newEmailType,
      localEmails,
      updateValue,
      newEmail,
      deleteEmail,
      saveNewEmail,
      updateType
    }
  }
}
</script>

<style scoped>
</style>
