export function getDate (date) {
  if (date.getDate) {
    return date.getDate()
  }
  if (date.seconds) {
    const n = new Date(0)
    n.setUTCSeconds(date.seconds)
    return n
  }
  return new Date(date)
}
