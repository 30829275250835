<template>

  <div class="field">
    <QuillEditor
      toolbar="#my-toolbar"
      theme="snow"
      :options="options"
      @update:content="handleUpdate"
      @ready="handleReady"
    >
      <template #toolbar>
        <div id="my-toolbar">
          <!-- Add buttons as you would before -->
              <label class="label">Bio</label>

          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-strike"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-list" value="ordered"></button>

          <!-- But you can also add your own -->
          <button id="custom-button">
            <span class="icon has-text-success" v-if="!saving">
              <i class="fas fa-check-circle"></i>
            </span>
            <span class="icon has-text-success" v-if="saving">
              <i class="fas fa-sync fa-spin"></i>
            </span>
          </button>
        </div>
      </template>
    </QuillEditor>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { db, user } from '../components/firebase.js'
import { ref } from 'vue'
export default {
  components: {
    QuillEditor
  },
  props: {
    id: String,
    biography: Object
  },
  setup (props, { emit }) {
    let quill
    // const alreadySet = false
    const saving = ref(false)

    function handleReady (readyQuill) {
      quill = readyQuill
      if (props.biography) {
        quill.setContents(props.biography.delta, 'silent')
      }
    }
    // watchEffect(() => {
    //   console.log(props.biography, quill)
    //   if (props.biography && quill && !alreadySet) {
    //     console.log('hi')
    //     alreadySet = true
    //     quill.setContents(props.biography.delta, 'silent')
    //   }
    // })
    async function handleUpdate (delta) {
      saving.value = true
      emit('updateBio', {
        biography: {
          delta: JSON.parse(JSON.stringify(delta)),
          text: quill.getText()
        }
      })
      await db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        biography: {
          delta: JSON.parse(JSON.stringify(delta)),
          text: quill.getText()
        }
      })
      saving.value = false
    }
    return {
      quill,
      handleReady,
      handleUpdate,
      saving
    }
  },
  data () {
    return {
      options: {
        // debug: 'info',
        modules: {
          toolbar: [
            'bold',
            'italic',
            'underline',
            'strike',
            { list: 'ordered' },
            { list: 'bullet' }
          ]
        },
        placeholder: 'Compose an epic...',
        theme: 'snow'
      }
    }
  }
}
</script>
