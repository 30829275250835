<template>
  <div class="box">
    <div class="field">
      <div class="control is-expanded">
        <textarea
          class="textarea is-small"
          placeholder="Notes..."
          v-model="newNote"
          rows="2"
          @keydown.enter="handleEnter"
        ></textarea>
      </div>
    </div>
    Add new
    <div class="select is-small">
      <select v-model="type">
        <option
          v-for="(option, index) in options"
          v-bind:value="option"
          :key="index"
        >
          {{ option }}
        </option>
      </select>
    </div>
    for
    <input
      class="input is-small"
      style="display: inline-block; width: 30%"
      type="date"
      v-model="date"
    />
    <a
      class="button is-info is-small"
      :class="{ 'is-loading': loading }"
      @click="addEvent"
    >
      <span class="icon is-small">
        <i class="fas fa-save"></i>
      </span>
      <span> Save </span>
    </a>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { db, user } from '../components/firebase.js'
import { useRoute } from 'vue-router'
export default {
  components: {},
  setup (props, { emit }) {
    const route = useRoute()
    const loading = ref(false)
    const type = ref('Note')
    const newNote = ref('')
    const date = ref(new Date().toISOString().split('T')[0])
    const options = ['Note', 'Reminder']
    const handleEnter = function (e) {
      if (e.ctrlKey || e.metaKey) {
        addEvent()
      }
    }
    const addEvent = async function () {
      if (!date.value || !type.value) {
        return
      }
      loading.value = true
      const key = new Date()
      emit('newEvent', {
        [Math.trunc(key.getTime() / 1000)]: {
          text: newNote.value,
          date: date.value,
          type: type.value,
          createdOn: { toDate: () => new Date() }
        }
      })

      await db
        .doc(`users/${user.value.uid}/relationships/${route.params.id}`)
        .update({
          [`events.${Math.trunc(key.getTime() / 1000)}`]: {
            text: newNote.value,
            date: date.value,
            type: type.value,
            createdOn: new Date()
          }
        })
      newNote.value = ''
      type.value = ''
      loading.value = false
    }
    return {
      options,
      type,
      date,
      newNote,
      loading,
      addEvent,
      handleEnter
    }
  }
}
</script>

<style>
</style>
