<template>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Birthday</label>
    </div>
    <div class="field-body">
      <div class="field">
        <p class="control is-expanded">
          <input
            class="input"
            @input="update"
            type="number"
            placeholder="Month"
            v-model="localBirthday.month"
            :disabled="!isEditing"
          />
        </p>
      </div>
      <div class="field">
        <p class="control is-expanded">
          <input
            class="input"
            @input="update"
            type="number"
            placeholder="Day"
            v-model="localBirthday.day"
             :disabled="!isEditing"
          />
        </p>
      </div>
      <div class="field">
        <p class="control is-expanded">
          <input
            class="input"
            @input="update"
            type="number"
            placeholder="Year"
            v-model="localBirthday.year"
             :disabled="!isEditing"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { db, user } from '../components/firebase.js'
export default {
  components: {},
  props: {
    birthday: Object,
    id: String,
    isEditing: Boolean
  },
  setup (props) {
    const localBirthday = ref({})

    watchEffect(() => {
      if (props.birthday) {
        localBirthday.value = props.birthday
      }
    })
    async function update (a, b, c) {
      const update = {
        'metadata.lastUpdated': new Date()
      }
      if (localBirthday.value.day) {
        update['birthday.day'] = localBirthday.value.day
      }
      if (localBirthday.value.month) {
        update['birthday.month'] = localBirthday.value.month
      }
      if (localBirthday.value.year) {
        update['birthday.year'] = localBirthday.value.year
      }
      await db.doc(`users/${user.value.uid}/relationships/${props.id}`).update(update)
    }
    return {
      localBirthday,
      update
    }
  }
  //   computed: {
  //     formattedDate () {
  //       console.log(this.note.date.toDate())
  //       console.log()
  //       return formatRelative(new Date(), new Date())
  //     }
  //   }
}
</script>

<style scoped>
.successfully-saved.hide-opacity {
  opacity: 0;
}

.successfully-saved {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}
</style>
