<template>
  <div class="tags" v-if="!isEditing">
    <a
      class="tag is-medium"
      v-for="(url, key) in localURLs"
      :key="key"
      :href="url.value"
      >
         <span class="icon" v-if="url.value.includes('linkedin.com')">
              <i class="fab fa-linkedin"></i>
            </span>
            <span class="icon" v-else-if="url.value.includes('facebook.com')">
              <i class="fab fa-facebook-square"></i>
            </span>
            <span v-else>
              {{ url.value.replace("https://", "").replace("http://", "") }}
            </span>
      </a
    >
  </div>
  <div v-if="isEditing">
    <div class="field has-addons" v-for="(url, key) in localURLs" :key="key">
      <div class="control is-expanded">
        <input
          class="input"
          type="text"
          v-model="url.value"
          @input="updateValue($event, key)"
        />
      </div>
      <div class="control" v-if="isEditing">
        <div class="button">
          <span class="icon has-text-danger" @click="deleteURL(key)">
            <i class="fas fa-trash"></i>
          </span>
        </div>
      </div>
    </div>
     <div class="field has-addons" >
    <div class="control is-expanded">
      <input
        class="input is-small"
        type="text"
        :disabled="!isEditing"
        placeholder="Add a new URL"
        v-model="newURL"
      />
    </div>
    <p class="control">
      <span class="button is-small" @click="saveNewURL">
        <span class="icon">
          <i class="fas fa-save" :class="{ 'fa-spin': saveInProgress }"></i>
        </span>
      </span>
    </p>
  </div>
  </div>

</template>

<script>
import { ref, watchEffect } from 'vue'
import { db, user } from './firebase.js'
import { sanitizeUrl } from '@braintree/sanitize-url'
export default {
  props: {
    isEditing: Boolean,
    id: String,
    URLs: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  setup (props) {
    const newURL = ref('')
    const localURLs = ref({})
    const saveInProgress = ref(false)
    watchEffect(() => {
      if (props.URLs) {
        localURLs.value = props.URLs
      }
    })
    function deleteURL (key) {
      delete localURLs.value[key]
      let count = 0
      const newURLs = {}
      Object.values(localURLs.value).forEach((l) => {
        newURLs[count] = l
        count++
      })
      db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        URLs: newURLs
      })
    }
    async function updateValue (event, key) {
      db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        [`URLs.${key}.value`]: event.target.value,
        [`URLs.${key}.metadata.updatedOn`]: new Date()
      })
    }
    async function saveNewURL () {
      saveInProgress.value = true
      console.log(sanitizeUrl)
      await db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        [`URLs.${Object.keys(props.URLs).length}`]: {
          metadata: {
            createdOn: new Date(),
            source: 'User Entered'
          },
          value: sanitizeUrl(newURL.value)
        }
      })

      localURLs.value[Object.keys(props.URLs).length] = {
        metadata: {
          createdOn: new Date(),
          source: 'User Entered'
        },
        value: newURL.value
      }
      newURL.value = ''
      saveInProgress.value = false
    }
    return {
      saveInProgress,
      localURLs,
      updateValue,
      deleteURL,
      saveNewURL,
      newURL
    }
  }
}
</script>

<style scoped>
</style>
