<template>
  <div class="about">
    <div class="columns is-centered">
      <div class="box column is-half">
        <div class="field is-grouped">
          <LargeInput
            :value="data.fname"
            :id="$route.params.id"
            :fieldName="'fname'"
            :placeholder="'First'"

          />
          <LargeInput
            :value="data.lname"
            :id="$route.params.id"
            :fieldName="'lname'"
            :placeholder="'Last'"

          />
        </div>

        <PhoneNumbers
          v-if="data.phoneNumbers || isEditing"
          :isEditing="isEditing"
          :id="$route.params.id"
          :phoneNumbers="data.phoneNumbers"
        />
        <Email
          :isEditing="isEditing"
          :id="$route.params.id"
          :emails="data.emails"
        />
        <URLs :isEditing="isEditing" :id="$route.params.id" :URLs="data.URLs" />

        <Address
          v-if="data.address || isEditing"
          :value="data.address"
          :id="$route.params.id"
        />

        <Biography
          v-if="localBio || isEditing"
          :id="data.id"
          :biography="data.biography"
          @updateBio="localBio = 'hi'"
        />

        <Birthday
          v-if="data.birthday || isEditing"
          :birthday="data.birthday"
          :id="data.id"
          :isEditing="isEditing"
        />

        <div class="field is-grouped">
          <div class="control">
            <button
              class="button is-danger"
              :class="{ 'is-outlined': !hasDeleteBeenClickedOnce }"
              @click="deleteRelationship"
            >
              <span v-if="!hasDeleteBeenClickedOnce">Delete</span>
              <span v-else>Are you sure?</span>
              <span class="icon is-small">
                <i class="fas fa-times"></i>
              </span>
            </button>
          </div>
          <div class="control" v-if="!isEditing">
            <button
              class="button is-link"
              :class="{ 'is-outlined': !isEditing }"
              @click="isEditing = !isEditing"
            >
              Edit
            </button>
          </div>
        </div>
        <!-- <pre v-if="data.googleContact" style="text-align: left">{{
          JSON.stringify(data.googleContact, null, 2)
        }}</pre> -->
      </div>
      <div class="column is-half">
        <Timeline :input="data" />

        <!-- <Note v-for="note in data.notes" :note="note" :key="note.date" /> -->
        <!-- <div class="field">
          <label class="label">Add New Note</label>
          <textarea
            class="textarea"
            placeholder="e.g. Hello world"
            v-model="newNote"
          ></textarea>
        </div>
        <div class="control">
          <button @click="addNote" class="button is-link">Save Note</button>
        </div>
        <Reminders
          :loadedReminders="data.reminders"
          @newReminder="handleNewReminder"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { db, user } from '../components/firebase.js'

import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import Note from '@/components/Note.vue'
import Email from '../components/Email.vue'
import PhoneNumbers from '../components/PhoneNumbers.vue'
import URLs from '../components/URLs.vue'
import Timeline from '@/components/Timeline.vue'
import Birthday from '@/components/Birthday.vue'
import LargeInput from '@/components/LargeInput.vue'
// import SmallInput from '@/components/SmallInput.vue'
import Address from '@/components/Address.vue'
import Biography from '@/components/Biography.vue'

export default {
  components: {
    PhoneNumbers,
    URLs,
    Address,
    Biography,
    Birthday,
    Timeline,
    LargeInput,
    // SmallInput,
    Email
  },
  setup (props) {
    const route = useRoute()
    const router = useRouter()
    const data = ref({})
    const newNote = ref('')
    const isEditing = ref(false)
    const localBio = ref(null)

    const loadData = function () {
      if (route.params.id) {
        db.collection('users')
          .doc(user.value.uid)
          .collection('relationships')
          .doc(route.params.id)
          .get()
          .then((snapshot) => {
            data.value = snapshot.data()
            if (data.value.biography) {
              localBio.value = data.value.biography
            }
            data.value.id = route.params.id
          })
      }
    }
    watch(
      () => route.path,
      () => {
        router.go()
      }
    )
    onMounted(() => {
      loadData()
    })
    const hasDeleteBeenClickedOnce = ref(false)
    const deleteRelationship = async function () {
      if (!hasDeleteBeenClickedOnce.value) {
        hasDeleteBeenClickedOnce.value = true
      } else {
        await db
          .doc(`users/${user.value.uid}/relationships/${route.params.id}`)
          .delete()
        router.push('/tend')
      }
    }
    const handleNewReminder = function (reminder) {
      if (!data.value.reminders) {
        data.value.reminders = {}
      }
      data.value.reminders = Object.assign(reminder, data.value.reminders)
    }
    const handleUpdate = function (event, field) {
      console.log(event, field)
    }
    const addNote = function () {
      const date = new Date()
      console.log(data.value)
      if (!data.value.notes) {
        data.value.notes = {}
      }
      data.value.notes[Math.trunc(date.getTime() / 1000)] = {
        text: newNote.value,
        date: { toDate: () => new Date() }
      }
      db.doc(`users/${user.value.uid}/relationships/${route.params.id}`).update(
        {
          [`notes.${Math.trunc(date.getTime() / 1000)}`]: {
            text: newNote.value,
            date: new Date()
          }
        }
      )
      newNote.value = ''
    }
    return {
      newNote,
      user,
      deleteRelationship,
      handleNewReminder,
      handleUpdate,
      data,
      addNote,
      hasDeleteBeenClickedOnce,
      isEditing,
      localBio
    }
  }
}
</script>

<style scoped>
</style>
