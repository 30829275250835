<template>
  <div class="timeline">
    <AddEvent @newEvent="addNewEvent" />

    <TimelineItem
      :event="event"
      v-for="event in dateBasedData.future"
      :key="event.date"
      @delete="handleDeletedItem"
      @edit="handleEditedItem"
    />
    <header class="timeline-header">
      <span class="tag is-primary">Today</span>
    </header>
    <TimelineItem
      :event="event"
      v-for="event in dateBasedData.historical"
      :key="event.date"
      @delete="handleDeletedItem"
      @edit="handleEditedItem"
    />
    <!-- <div class="timeline-header">
      <span class="tag is-medium is-primary">End</span>
    </div> -->
  </div>
</template>

<script>
import TimelineItem from './TimelineItem.vue'
import { getDate } from './utility.js'
import { ref, watchEffect } from 'vue'
import AddEvent from '@/components/AddEvent.vue'
export default {
  components: {
    TimelineItem,
    AddEvent
  },
  props: {
    input: Object
  },

  setup (props) {
    const data = ref({})

    watchEffect(() => {
      data.value = props.input
    })
    function addNewEvent (event) {
      data.value.events = Object.assign(event, data.value.events)
      console.log(data.value)
    }
    function isNewEvent (type) {
      if (type === 'notes' || type === 'reminders') {
        return false
      }
      return true
    }
    const handleDeletedItem = function (item) {
      if (isNewEvent(item.type)) {
        delete data.value.events[item.id]
      } else {
        delete data.value[item.type][item.id]
      }
    }
    const handleEditedItem = function (item) {
      if (isNewEvent(item.type)) {
        data.value.events[item.id].text = item.newText
        data.value.events[item.id].date = item.date
      } else {
        data.value[item.type][item.id].text = item.newText
      }
    }
    return {
      data,
      handleDeletedItem,
      handleEditedItem,
      addNewEvent
    }
  },
  computed: {
    dateBasedData () {
      const historical = []
      const future = []
      if (this.data.birthday) {
        const currentYear = new Date().getFullYear()
        const thisYearsBirthday = new Date(
          currentYear,
          this.data.birthday.month,
          this.data.birthday.day
        )
        if (new Date() > thisYearsBirthday) {
          thisYearsBirthday.setYear(currentYear + 1)
        }
        future.push({
          text: 'Birthday',
          isBirthday: true,
          date: `${currentYear}-${this.data.birthday.month}-${this.data.birthday.day}`
        })
      }
      if (this.data.notes) {
        Object.keys(this.data.notes).forEach((n) => {
          if (this.data.notes[n].date.toDate) {
            if (this.data.notes[n].date.toDate() < new Date()) {
              historical.push(
                Object.assign(
                  { isNote: true, id: n, type: 'notes' },
                  this.data.notes[n]
                )
              )
            } else {
              future.push(
                Object.assign(
                  { isNote: true, id: n, type: 'notes' },
                  this.data.notes[n]
                )
              )
            }
          } else {
            if (new Date(this.data.notes[n].date) < new Date()) {
              historical.push(
                Object.assign(
                  { isNote: true, id: n, type: 'notes' },
                  this.data.notes[n]
                )
              )
            } else {
              future.push(
                Object.assign(
                  { isNote: true, id: n, type: 'notes' },
                  this.data.notes[n]
                )
              )
            }
          }
        })
      }
      if (this.data.events && Object.values(this.data.events).length) {
        Object.keys(this.data.events).forEach((r) => {
          if (getDate(this.data.events[r].date) < new Date()) {
            historical.push(Object.assign({ id: r }, this.data.events[r]))
          } else {
            future.push(Object.assign({ id: r }, this.data.events[r]))
          }
        })
      }
      return {
        historical: historical.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        ),
        future: future.sort((a, b) => new Date(b.date) - new Date(a.date))
      }
    }
  }
}
</script>
