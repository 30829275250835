<template>
  <div class="field has-addons" v-for="(phoneNumber, key) in localPhoneNumbers" :key="key">
    <p class="control">
      <span class="button" v-if="!isEditing">{{ phoneNumber.type }} </span>
      <span class="select" v-if="isEditing">
        <select v-model="phoneNumber.type" @change="updateType($event, key)">
          <option>Work</option>
          <option>Mobile</option>
        </select>
      </span>
    </p>
    <div class="control is-expanded">
      <input
        class="input"
        type="tel"
        :disabled="!isEditing"
        v-model="phoneNumber.value"
        @input="updateValue($event, key)"
      />
    </div>
    <div class="control" v-if="isEditing">
      <div class="button" @click="deletePhoneNumber(key)">
        <span class="icon has-text-danger" >
          <i class="fas fa-trash"></i>
        </span>
      </div>
    </div>
  </div>

  <div class="field has-addons" v-if="isEditing">
    <div class="control">
      <span class="select is-small">
        <select v-model="newPhoneNumberType">
          <option>Work</option>
          <option>Mobile</option>
        </select>
      </span>
    </div>
    <div class="control is-expanded">
      <input
        class="input is-small"
        type="text"
        :disabled="!isEditing"
        placeholder="(415) TRI-STAN"
        v-model="newPhoneNumber"
      />
    </div>
    <p class="control">
      <span class="button is-small" @click="saveNewPhoneNumber">
        <span class="icon">
          <i class="fas fa-save" :class="{ 'fa-spin': saveInProgress }"></i>
        </span>
      </span>
    </p>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { db, user } from '../components/firebase.js'
export default {
  props: {
    isEditing: Boolean,
    id: String,
    phoneNumbers: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  setup (props) {
    const newPhoneNumberType = ref('Mobile')
    const newPhoneNumber = ref('')
    const localPhoneNumbers = ref({})
    const saveInProgress = ref(false)
    watchEffect(() => {
      if (props.phoneNumbers) {
        localPhoneNumbers.value = props.phoneNumbers
      }
    })
    function deletePhoneNumber (key) {
      delete localPhoneNumbers.value[key]
      let count = 0
      const newPhoneNumbers = {}
      Object.values(localPhoneNumbers.value).forEach((l) => {
        newPhoneNumbers[count] = l
        count++
      })
      db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        phoneNumbers: newPhoneNumbers
      })
    }
    async function updateType (event, key) {
      db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        [`phoneNumbers.${key}.type`]: event.target.value,
        [`phoneNumbers.${key}.metadata.updatedOn`]: new Date()
      })
    }
    async function updateValue (event, key) {
      db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        [`phoneNumbers.${key}.value`]: event.target.value,
        [`phoneNumbers.${key}.metadata.updatedOn`]: new Date()
      })
    }
    async function saveNewPhoneNumber () {
      saveInProgress.value = true
      const newData = {
        metadata: {
          createdOn: new Date(),
          source: 'User Entered'
        },
        type: newPhoneNumberType.value,
        value: newPhoneNumber.value
      }
      await db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        [`phoneNumbers.${Object.keys(props.phoneNumbers).length}`]: newData
      })
      localPhoneNumbers[Object.keys(props.phoneNumbers).length] = newData
      newPhoneNumber.value = ''
      saveInProgress.value = false
    }
    return {
      saveInProgress,
      newPhoneNumberType,
      localPhoneNumbers,
      updateValue,
      newPhoneNumber,
      deletePhoneNumber,
      saveNewPhoneNumber,
      updateType
    }
  }
}
</script>

<style scoped>
</style>
