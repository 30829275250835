<template>
  <div
    class="control is-expanded is-large "
    :class="{ 'is-loading': loading , 'has-icons-right': !loading }"
  >
    <input
      class="input is-large"
      type="text"
      :placeholder="placeholder"
      @change="handleUpdate"
      v-model="text"
    />
    <span
      class="icon is-medium is-right has-text-success  successfully-saved"
      v-if="!loading"
      :class="{ 'hide-opacity': !successfulSave }"
    >
      <i class="fas fa-check-circle"></i>
    </span>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { db, user } from '../components/firebase.js'
export default {
  props: {
    value: String,
    id: String,
    fieldName: String,
    placeholder: String
  },
  setup (props) {
    const text = ref(null)
    const loading = ref(false)
    const successfulSave = ref(false)
    watchEffect(() => {
      if (props.value) {
        text.value = props.value
      }
    })
    async function handleUpdate (event) {
      loading.value = true
      console.log(props.id, props.fieldName, props.placeholder)
      await db.doc(`users/${user.value.uid}/relationships/${props.id}`).update({
        [props.fieldName]: text.value
      })
      loading.value = false
      successfulSave.value = true
      setTimeout(() => {
        successfulSave.value = false
      }, 1000)
    }
    return {
      handleUpdate,
      loading,
      successfulSave,
      text
    }
  }
}
</script>

<style scoped>
input {
  box-shadow: none;
  border: none;
}
.successfully-saved.hide-opacity {
  opacity: 0;
}

.successfully-saved {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}
</style>
