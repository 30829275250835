<template>
  <div class="timeline-item">
    <div class="timeline-marker is-icon">
      <i
        class="fa"
        :class="{
          'fa-sticky-note': event.isNote || event.type === 'Note',
          'fa-user-clock': event.isReminder || event.type === 'Reminder',
          'fa-comment-dots': event.type === 'Message',
          'fa-birthday-cake': event.isBirthday,
          'fa-phone': event.type === 'Call',
        }"
      ></i>
    </div>
    <div class="timeline-content">
      <p v-if="!editMode" class="heading">{{ localDate }}</p>
      <input
        v-if="editMode"
        class="input"
        type="date"
        :value="event.date"
        @change="editEventDate"
      />

      <span class="icon-text">
        <p v-if="!editMode">{{ event.text }}</p>
        <textarea v-if="editMode" class="textarea" v-model="newText"></textarea>

        <a v-if="!editMode && !event.isBirthday" @click="editMode = true">
          <span class="icon">
            <i class="fas fa-edit"></i>
          </span>
        </a>
        <a v-if="editMode" @click="editEvent">
          <span class="icon">
            <i class="fas fa-save" :class="{ 'fa-spin': editInProgress }"></i>
          </span>
        </a>
        <div class="select"  v-if="editMode" @change="editEventStatus">
          <select :value="event.status">
            <option value="Complete">Complete</option>
            <option value="Active">Active</option>
          </select>
        </div>
        <a @click="deleteEvent" v-if="editMode" class="has-text-danger">
          <span class="icon">
            <i
              class="fas fa-trash"
              :class="{ 'fa-spin': deletionInProgress }"
            ></i>
          </span>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { db, user, fieldDelete } from '../components/firebase.js'
import { useRoute } from 'vue-router'
export default {
  props: {
    event: Object
  },
  emits: ['delete', 'edit'],
  setup (props, { emit }) {
    const route = useRoute()
    const editMode = ref(false)
    const localDate = ref(props.event.date)
    const deletionInProgress = ref(false)
    const editInProgress = ref(false)
    const newText = ref(props.event.text)
    function isNewEvent (type) {
      if (type === 'notes' || type === 'reminders') {
        return false
      }
      return true
    }
    const deleteEvent = async function () {
      deletionInProgress.value = true
      if (isNewEvent(props.event.type)) {
        await db
          .doc(`users/${user.value.uid}/relationships/${route.params.id}`)
          .update({
            [`events.${props.event.id}`]: fieldDelete()
          })
      } else {
        await db
          .doc(`users/${user.value.uid}/relationships/${route.params.id}`)
          .update({
            [`${props.event.type}.${props.event.id}`]: fieldDelete()
          })
      }

      emit('delete', props.event)
    }
    const editEventDate = async function (event) {
      if (isNewEvent(props.event.type)) {
        await db
          .doc(`users/${user.value.uid}/relationships/${route.params.id}`)
          .update({
            [`events.${props.event.id}.date`]: event.target.value,
            [`events.${props.event.id}.updatedOn`]: new Date()
          })
      } else {
        await db
          .doc(`users/${user.value.uid}/relationships/${route.params.id}`)
          .update({
            [`${props.event.type}.${props.event.id}.date`]: event.target.value,
            [`${props.event.type}.${props.event.id}.updatedOn`]: new Date()
          })
      }

      localDate.value = event.target.value
      editMode.value = false
    }
    const editEventStatus = async function (event) {
      await db
        .doc(`users/${user.value.uid}/relationships/${route.params.id}`)
        .update({
          [`events.${props.event.id}.status`]: event.target.value,
          [`events.${props.event.id}.updatedOn`]: new Date()
        })
    }
    const editEvent = async function () {
      editInProgress.value = true
      if (isNewEvent(props.event.type)) {
        await db
          .doc(`users/${user.value.uid}/relationships/${route.params.id}`)
          .update({
            [`events.${props.event.id}.text`]: newText.value,
            [`events.${props.event.id}.updatedOn`]: new Date()
          })
      } else {
        await db
          .doc(`users/${user.value.uid}/relationships/${route.params.id}`)
          .update({
            [`${props.event.type}.${props.event.id}.text`]: newText.value,
            [`${props.event.type}.${props.event.id}.updatedOn`]: new Date()
          })
      }

      emit('edit', Object.assign({ newText: newText.value }, props.event))
      editMode.value = false
    }
    return {
      editMode,
      deleteEvent,
      editEvent,
      deletionInProgress,
      editInProgress,
      newText,
      editEventDate,
      localDate,
      editEventStatus
    }
  }
  // mounted () {
  //   console.log(this.note.date.toDate())
  //   console.log(formatRelative)
  // },
  // computed: {
  //   formattedDate () {
  //     console.log(this.note.date.toDate())
  //     console.log()
  //     return formatRelative(new Date(), new Date())
  //   }
  // }
}
</script>

<style scoped>
.timeline-content {
  width: 100%;
}
</style>
