<template>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Address</label>
    </div>
    <div class="field-body">
      <div class="dropdown is-active">
        <div class="dropdown-trigger">
          <div class="field" v-if="text && !editMode">
            {{ text.formatted_address }}
            <a @click="editMode = true">
              <span class="icon">
                <i class="fas fa-edit"></i>
              </span>
            </a>
          </div>
          <div
            v-else
            class="control is-expanded"
            :class="{ 'is-loading': loading, 'has-icons-right': !loading }"
          >
            <input class="input" type="text" v-model="inputText" />

            <span
              class="
                icon
                is-medium is-right
                has-text-success
                successfully-saved
              "
              v-if="!loading"
              :class="{ 'hide-opacity': !successfulSave }"
            >
              <i class="fas fa-check-circle"></i>
            </span>
            <div id="places"></div>
          </div>
        </div>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content" v-if="suggestions.length">
            <a
              class="dropdown-item"
              @click="saveAddress(suggestion.place_id)"
              v-for="suggestion in suggestions"
              :key="suggestion.place_id"
            >
              {{ suggestion.description }}
            </a>

            <span class="dropdown-item" style="text-align: right">
              <img src="../assets/powered_by_google_on_white.png" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { db, user } from '../components/firebase.js'
import { Loader } from '@googlemaps/js-api-loader'
// let google
export default {
  props: {
    value: Object,
    id: String
  },
  setup (props) {
    let session
    const editMode = ref(false)
    const suggestions = ref([])
    const inputText = ref('')
    let autocomplete
    let placesService
    const loader = new Loader({
      apiKey: 'AIzaSyD1cLkTLNHQUv1C_ojM7GSdWE7BjCwBFIs',
      version: 'quarterly',
      libraries: ['places']
    })
    loader.load().then(async () => {
      autocomplete = new window.google.maps.places.AutocompleteService()
      session = new window.google.maps.places.AutocompleteSessionToken()
    })
    function saveAddress (placeId) {
      loading.value = true
      if (!placesService) {
        placesService = new window.google.maps.places.PlacesService(
          document.querySelector('#places')
        )
      }
      placesService.getDetails(
        {
          placeId,
          fields: [
            'address_components',
            'adr_address',
            'formatted_address',
            'geometry'
          ]
        },
        async (result) => {
          suggestions.value = []
          result.geometry.location = {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng()
          }
          result.geometry.viewport = result.geometry.viewport.toJSON()
          await db
            .doc(`users/${user.value.uid}/relationships/${props.id}`)
            .update({
              address: result
            })
          loading.value = false
          successfulSave.value = true
          text.value = result
          setTimeout(() => {
            successfulSave.value = false
            editMode.value = false
          }, 50)
        }
      )
    }
    watchEffect(() => {
      if (inputText.value.length > 5) {
        autocomplete.getPlacePredictions(
          {
            input: inputText.value,
            types: ['address'],
            session
          },
          (results) => {
            suggestions.value = results
            console.log(results)
          }
        )
      } else {
        suggestions.value = []
      }
    })
    const text = ref(null)
    const loading = ref(false)
    const successfulSave = ref(false)
    watchEffect(() => {
      if (props.value) {
        text.value = props.value
        console.log(props.value)
      }
    })

    return {
      loading,
      successfulSave,
      text,
      suggestions,
      inputText,
      saveAddress,
      editMode
    }
  }
}
</script>

<style scoped>
input {
  box-shadow: none;
  border: none;
}
.successfully-saved.hide-opacity {
  opacity: 0;
}

.successfully-saved {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}
</style>
